.modal-box-diy {
    top:0;
    left: 0;
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background:rgba(0,0,0,0.5);
}
.active_modal {
    display: flex;
}
@media screen and (min-width:1001px) {
    .appFooter {
        display: none;
    }
    .phone {
        display: none;
    }
    .mask {
        display: none;
    }
    .makeCall {
        display: none;
    }
    .sidebar {
        position: fixed;
        right: 0px;
        top: 26%;
        width: auto;
        z-index: 50;
    }

    .sidebar-item {
        display: block;
        width: 70px;
        height: 70px;
        background: #019cff;
        margin-top: 1px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 10px;
        cursor: pointer;
        z-index: 100;
        position: relative;
    }

    .sidebar-item>p {
        color: white;
        font-size: 14px;
        margin-top: 6px;
        z-index: 100;
    }

    .sidebar-item:hover {
        background-color: #085d9b;
    }

    .sidebar-item>img {
        z-index: 100;
    }

    .hotline-detail {
        z-index: 10;
        position: absolute;
        background: #019cff;
        width: 150px;
        height: 70px;
        top: 71px;
        right: -80px;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        line-height: 70px;
        color: white;
        transition: 0.2s;
    }

    .sidebar-box .gzh-QRcode {
        z-index: 100;
        bottom: 0;
        right: 80px;
        width: 0px;
        height: 0px;
        display: none;
        position: absolute;
        transition: 1.0s;
    }

    .sidebar-box .show-gzh-QRcode {
        display: block;
        /* width: 100%; */
        width: 449px;
        height: 150px;
    }

    .gzh-QRcode>img {
        height: 150px;
        width: 449px;
        display: block;
    }

    .hotline-active {
        right: 72px;
    }

    .sidebar-box .side-chat {
        z-index: 999;
        background: #019cff;
        height: 50px;
        width: 150px;
        position: fixed;
        right: 0;
        bottom: 0;
        color: white;
        font-size: 16px;
        line-height: 50px;
        text-align: center;
        border-radius: 10px 0 0 0;
    }

    .sidebar-box .side-chat:hover {
        background-color: #085d9b;
        cursor: pointer;
    }

    .sidebar-box .sidebar a {
        display: block;
        padding: 10px 0 0 0px;
    }

    .sidebar-box .sidebar a:hover {
        background-color: #085d9b;
    }

    .sideContainer {
        position: fixed;
        right: 10px;
        top: 26%;
        width: auto;
        z-index: 50;
        color: #fff;
    }
    .sideContain {
        width: 132px;
        /* height: 256px; */
        height: 200px;
        background: linear-gradient(62deg,rgba(30,128,255,1) 0%,rgba(56,162,255,1) 100%);
        border-radius: 64px 64px 4px 4px;
        padding-top: 12px;
    }
    .sideCircle {
        width: 108px;
        height: 108px;
        border: 1px solid rgba(255,255,255,1);
        border-radius: 50%;
        margin: 0 auto 18px;
        
        position: relative;
        animation: circleRoate 1s  infinite linear ;
    }

    .closesidebtn {
        position: absolute;
        top: -24px;
        right: -6px;
        width: 20px;
        height: 20px;
        text-align: center;
        cursor: pointer;
    }
    .closesidebtn img{
        width: 12px;
        height: 12px;
    }
    .smallcircle {
        position: absolute;
        top: 5px;
        right: 20px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #fff;
    
    }
    .salesPhone div {
        text-align: center;
        margin-bottom: 4px;
        
    }
    @keyframes circleRoate{
        from{transform: rotate(0deg) infinite;}
        to{transform: rotate(360deg);}
    }
    .zixunBtn {
        width:100px;
        height:32px;
        background:rgba(255,255,255,1);
        border-radius:4px;
        text-align: center;
        line-height: 32px;
        color: #2866D2;
        font-size: 14px;
        font-weight:bold;
        margin: 20px auto 0;
        cursor: pointer;
    }
    .zixunBtn:hover {
        box-shadow:  0 0 5px rgba(0,0,0,0.5);
    }
    .shuLine {
        width: 1px;
        height: 36px;
        background: #38A2FF;
        margin: 0 auto;
    }
    .sideCircleSmall {
        width: 60px;
        height: 60px;
        background:rgba(30,128,255,1);
        border-radius: 50%;
        margin: 0 auto;
        text-align: center;
        padding-top: 10px;
    }
    .sideCircleSmall div {
        margin-top: 3px;
        font-size: 12px;
    }
    .uptoTopCircle {
        width: 60px;
        height: 60px;
        background:#fff;
        border-radius: 50%;
        margin: 0 auto;
        text-align: center;
        border: 1px solid rgba(30,128,255,1);
        padding-top: 17px;
        cursor: pointer;
    }
    .qrCircle {
        cursor: pointer;
        position: relative;
        padding-top: 6px;
    }
    .qrSide {
        position: absolute;
        left: -230px;
        top: -30px;
        width: 194px;
        height: 110px;
        background: linear-gradient(62deg,rgba(30,128,255,1) 0%,rgba(56,162,255,1) 100%);
        border-radius: 10px;   
        padding-top: 12px;
        transition: 1s;
        opacity: 0;
    }
    .qrSide .title {
        display: inline-block;
        margin-right: 10px;
        font-size:22px;
        vertical-align: middle;
        margin-top: 10px;
    }
    .qrSide .title div {
        margin-bottom: 10px;
    }
    .qrSide  img {
        width:  85px;
        height: 85px;
    }

    .hide {
    opacity: 1;
    display: none;
    transition: 0.6s;
    }


    .modal-qrcode {
        width: 400px;
        height: 500px;
        background: white;
        border-radius: 24px;
        align-items: center;
        position: relative;
        padding: 20px 0;
        box-sizing: border-box;
    }
    .modal-close {
        position: absolute;
        width: 40px;
        height: 40px;
        color: #fff;
        font-size: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
        bottom: -80px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        display: block;
        line-height: 34px;
    }
    .modal-qrcode .wxqrcode {
        width: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


}

@media screen and (max-width:1000px) {
    /* .terminal {
        display: none;
    } */
    body {
        font-size: 20px !important;
    }
    .appFooter{
        width: 100%;
        padding: 0 41px 60px;
        box-sizing: border-box;
        background: #212225;
    }
    .appFooter .footer_title {
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 54px;
        font-size: 25px;
    }
    .appFooter .footer_item{
        color: #fff;
        font-size: 22px;
        margin-bottom: 20px;
    }
    .appFooter .footer_item a{
        color: #fff;
    }
    .footer_head {
        height: 116px;
        border-bottom: 1px solid #eee;
    }
    .footer_head .footer_img {
        width: 168px;
        height: 44px;
    }
    .appconsultation {
        width: 50%;
        display: flex;
        justify-content: center;
        text-align: center;
        flex: 1;
    }
    .appconsultation  .home {
        padding-left: 95px;
        padding-right: 95px;
        text-align: center;
        /* line-height: 55px; */
    }
    .appconsultation  .home img {
        width: 36px;
        height: 36px;
        margin-top: 40px;
    }
    .appconsultation  .home a {
      color: #5D5D66;
      font-size: 1.25em;
      font-weight: bold;
    }
    .appconsultation  .home p {
        font-size:1.25em;
        font-family:PingFang SC;
        color: #5D5D66;
        padding-top: 10px;
    }
    .appconsultation .onlineConsultation {
        padding-right: 95px;
        font-family:PingFang SC;
        color: #5D5D66;
   
    }
    .appconsultation .onlineConsultation p {
        font-size:1.25em;
        font-family:PingFang SC;
        color: #5D5D66;
        padding-top: 10px;
    }
    .appconsultation .onlineConsultation img {
        width: 36px;
        height: 36px;
        margin-top: 40px;
    }
    .appFooter .probation {
        background:linear-gradient(90deg,rgba(250,182,2,1) 0%,rgba(252,174,2,1) 100%);
        font-size:2.125em;
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(255,255,255,1);
        text-align: center;
        width: 50%;
        display: block;
        color: #fff;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .phone {
        width:108px;
        height:108px;
        border:1px solid rgba(229,229,229,1);
        background:linear-gradient(-20deg,rgba(255,90,0,1) 0%,rgba(255,188,130,1) 100%);
        border-radius:50%;
        position: fixed;
        bottom: 269px;
        right: 32px;
    }
    .phone .img {
        width: 78px;
        height: 78px;
        margin-top: 20px;
        margin-left: 14px;
    }
    .phone .title {
        background:rgba(255,106,25,1);
        border:2px solid rgba(255,255,255,1);
        border-radius:6px;
        color: #FFFFFF;
        font-size: 20px;
        text-align: center;
    }
    .liImg {
        position: absolute;
        width: 65px;
        height: 68px;
        top: 22px;
        left: 20px;
        animation: circleRoate 1.2s  infinite linear ;
    }
    .waiImg {
        position: absolute;
        width: 86px;
        height: 84px;
        top: 13px;
        left: 10px;
        animation: circleRoate 1s  infinite linear ;
    }
    .mask {
        width: 100vh;
        height: 100vh;
        background:rgba(0,0,0,.7);
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        display: none;
    }
    .makeCall {
        width: 72%;
        height: 784px;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        z-index: 9999;
        display: none;
        border-radius:10px;
    }
    .makeCall .closemask {
        color:#CCCCCC;
        font-size: 2.5em;
        text-align: right;
        margin-right: 23px;
        margin-top: 28px;
        margin-bottom: 8px;
    }
    .makeCall img {
        width: 240px;
        height: 240px;
        display: block;
        margin: 0 auto 27px;
    }
    .makeCall .consultationHotline {
        text-align: center;
        font-size:1.875em;
        font-family:PingFang SC;
        font-weight:400;
        color:#1A1A1A;
        font-weight: bold;
    }
    .num {
        font-size:2.25em;
        font-family:PingFang SC;
        font-weight:600;
        color:rgba(24,129,250,1);
        line-height:38px;
        text-align: center;
        margin-top: 22px;
        font-weight: bold;
    }
    .button {
    }
    .button>a{
        color: #fff;
        width:53%;
        font-size: 2em;
        font-weight:500;
        display: block;
        background:rgba(53,115,255,1);
        padding-top: 25px;
        padding-bottom: 25px; 
        margin: 22px auto 0;
        text-align: center;
        border-radius: 10px;
        margin-top: 80px;
        font-weight: bold;
    }
    @keyframes circleRoate{
        from{transform: rotate(0deg) infinite;}
        to{transform: rotate(360deg);}
    }
    .modal-qrcode {
        width: 700px;
        height: 800px;
        background: white;
        border-radius: 24px;
        align-items: center;
        position: relative;
        padding: 20px 0;
        box-sizing: border-box;
    }
    .modal-close {
        position: absolute;
        width: 80px;
        height: 80px;
        color: #fff;
        font-size: 50px;
        border-radius: 50%;
        border: 5px solid #fff;
        bottom: -148px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
    .modal-qrcode .wxqrcode {
        width: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
